<template>
    <v-container fluid class="mb-12">
        <v-row class="mb-2">
            <v-col cols="12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
                <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                    <v-layout align-center pr-4>
                        <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                        <v-layout column>
                            <div>
                            <strong>{{ snackbar.title }}</strong>
                            </div>
                            <div>{{ snackbar.text }}</div>
                        </v-layout>
                    </v-layout>
                    <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                        <v-icon>clear</v-icon>
                    </v-btn>
                </v-snackbar>
            </v-col>
            <v-col cols="12">
                <v-card outlined class="p-0">
                    <v-container fluid class="p-3">
                        <v-row class="mb-1">
                            <v-col cols="12">
                                <h6 class="indigo-text m-0 primary--text"><v-icon color="primary" class="mr-3">mdi-filter</v-icon> Filter: </h6>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="col-12" sm="6" md="2" lg="2">
                                <v-menu
                                    ref="modal"
                                    v-model="modal"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="auto"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        solo
                                        dense
                                        v-model="date_from"
                                        label="Start Trans Date"
                                        persistent-hint
                                        prepend-inner-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        class="ma-0 pa-0 border-12"
                                        hide-details=true
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="date_from"
                                        no-title
                                        @input="modal = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col class="col-12" sm="6" md="2" lg="2">
                                <v-menu
                                    ref="modal_to"
                                    v-model="modal_to"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="auto"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        solo
                                        dense
                                        v-model="date_to"
                                        label="Date to"
                                        persistent-hint
                                        prepend-inner-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        class="ma-0 pa-0 border-12"
                                        hide-details=true
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="date_to"
                                        no-title
                                        @input="modal_to = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2" lg="2">
                                <v-autocomplete
                                    prepend-inner-icon="mdi-fireplace-off"
                                    dense
                                    solo
                                    clearable
                                    v-model="mill_id"
                                    :items="mills"
                                    item-value="mill_id"
                                    item-text="mill_name"
                                    label="MILL ID"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                    :disabled="disabled_mill"
                                    @change="(event) => [updateWarehouseFilter(event)]"
                                ></v-autocomplete>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2">
                                <v-autocomplete
                                    prepend-inner-icon="mdi-map-marker"
                                    dense
                                    solo
                                    clearable
                                    v-model="warehouse_id"
                                    :items="warehouses"
                                    item-value="wh_id"
                                    item-text="descr"
                                    label="WH ID"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                    :loading="loading_warehouse"
                                    :disabled="disabled_wh"
                                    @change="(event) => getBin(event)"
                                ></v-autocomplete>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2">
                                <v-autocomplete
                                    prepend-inner-icon="mdi-select-place"
                                    dense
                                    solo
                                    clearable
                                    v-model="bin_wh"
                                    :items="bin_whs"
                                    item-value="bin_id"
                                    item-text="descr"
                                    label="BIN ID *"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                    disabled
                                ></v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row align="center" justify="center" class="mt-5">
                            <v-col class="col-6" sm="6" md="2">
                                <v-btn class="border-12" block  style="padding: 20px;"
                                elevation="2" small @click="[clear(), disabled_search = false]">Clear</v-btn>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2">
                                <v-btn class="border-12" block style="padding: 20px;"
                                color="info" elevation="2" small @click="[fetchData()]" :disabled="disabled_search"><v-icon>mdi-magnify</v-icon> Search</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>
        <v-row class="mb-12">
            <v-col class="col-12" sm="12" md="8">
                <v-card outlined class="p-0">
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12">
                                <h6 class="indigo-text m-0 primary--text"><v-icon color="primary" class="mr-3">mdi-table</v-icon> List Transaction </h6>
                            </v-col>
                            <v-col cols="12">
                                <v-data-table
                                    dense
                                    :headers="header_wh_transes"
                                    :items="transactions"
                                    class="elevation-1"
                                    :items-per-page="30"
                                    :footer-props="{
                                        'items-per-page-options': [10, 20, 30, 40, 50, -1]
                                    }"
                                    :loading="$store.state.overlay"
                                    height="425"
                                    fixed-header
                                    :divider="true"
                                    :light="true"
                                    :search="searchItemTrans" 
                                    :item-class="tr_datatable"
                                >    
                                    <template v-slot:top>
                                        <v-toolbar flat color="white" class="mt-2 mb-2">
                                            <div class="d-flex w-100">
                                                <v-text-field solo style="max-width: 350px;" class="border-12 mr-3" v-model="searchItemTrans" append-icon="mdi-magnify" label="Search" dense single-line hide-details></v-text-field>
                                                <v-spacer></v-spacer>
                                                <v-btn
                                                small
                                                color="success"
                                                class="py-5"
                                                @click="addDialog()"
                                                v-if="accessTrans == 'Y'"
                                                >
                                                    <v-icon>mdi-plus</v-icon>
                                                    Create New
                                                </v-btn>
                                            </div>
                                        </v-toolbar>
                                    </template>
                                    <template v-slot:[`item.width`]="{ item }">
                                        {{ $store.getters.convertToCurrencyNoFrac(item.width) }}
                                    </template> 
                                    <template v-slot:[`item.wgt`]="{ item }">
                                        {{ $store.getters.convertToCurrencyUs(item.wgt) }}
                                    </template> 
                                    <template v-slot:[`item.thick`]="{ item }">
                                        {{ $store.getters.convertToCurrencyUs(item.thick) }}
                                    </template> 
                                    <template v-slot:[`item.tr_date`]="{ item }">
                                        {{ (new Date(new Date(item.tr_date) - (new Date()).getTimezoneOffset() *
                                            60000)).toISOString().substr(0, 10) }}
                                    </template>
                                    <template v-slot:[`item.actions`]="{ item }">
                                        <v-menu
                                            bottom
                                            right
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn
                                                    dark
                                                    icon
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    color="grey"
                                                    class="text-center"
                                                >
                                                    <v-icon>mdi-dots-vertical</v-icon>
                                                </v-btn>
                                            </template>

                                            <v-list dense>
                                                <v-list-item @click="showItem(item)">
                                                    <v-list-item-icon>
                                                        <v-icon>mdi-magnify</v-icon>
                                                    </v-list-item-icon>
                                                    <v-list-item-content>
                                                        <v-list-item-title>Detail</v-list-item-title>
                                                    </v-list-item-content>
                                                </v-list-item>
                                                <v-list-item v-if="accessTrans == 'Y' && periode == item.period" @click="editItem(item)">
                                                    <v-list-item-icon>
                                                        <v-icon>mdi-pencil</v-icon>
                                                    </v-list-item-icon>
                                                    <v-list-item-content>
                                                        <v-list-item-title>Edit</v-list-item-title>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>      
            <v-col class="col-12" sm="12" md="4">
                <v-card outlined class="p-0" height="610">
                    <v-container fluid class="p-3" style="overflow-y: auto;height: 545px;">
                        <v-row class="mb-1">
                            <v-col cols="12" v-if="itemDetail == null">
                                <v-img
                                class="text-center mx-auto"
                                lazy-src="@/assets/empty_data.png"
                                max-width="300"
                                src="@/assets/empty_data.png"
                                style="margin-top: 100px;"
                                ></v-img>
                                <p class="text-center">
                                    Please choice Header
                                </p>
                            </v-col>
                            <v-col cols="12" v-else >
                                <v-list dense class="mt-0 pt-0">
                                    <v-subheader><strong>Transaction Item {{ itemDetail ? itemDetail.tr_id : '' }}</strong></v-subheader>
                                    <v-list-item-group v-for="(item, i) in itemDetails"
                                        :key="i">
                                        <v-list-item>
                                            <v-list-item-content>
                                                <div class="d-flex w-100">
                                                    <div class="w-15">
                                                        <p class="mt-1">
                                                        {{item.prod_code}} | 
                                                        </p>
                                                    </div>
                                                    <div class="w-65">
                                                        <p class="mt-1">
                                                        {{ item.NamaBarang }}
                                                        </p>
                                                    </div>
                                                    <div class="w-30 text-center">
                                                        <div class="input-group spinner number">
                                                            <input class="form-control" type="text" name="quantity" min="1"  :max="item.qty" v-model="item.qty" style="text-align:center; font-size: 12px;" disabled>
                                                        </div>
                                                    </div>   
                                                    <!-- <div class="w-15 text-right">
                                                        <v-icon color="red" class="mt-1" @click="deleteWhDetail(item)" v-if="accessTrans == 'Y'">
                                                            mdi-delete
                                                        </v-icon>
                                                    </div>                                                  -->
                                                </div>
                                                <div class="w-100 mt-1">
                                                    <div class="d-flex ml-1">
                                                        <v-switch
                                                        label="Detail"
                                                        hide-details
                                                        class="pt-0 mt-0"
                                                        v-model="item.display"
                                                        ></v-switch>
                                                    </div>
                                                </div>
                                                <v-expand-transition mode="in-out">
                                                    <div class="w-100 mt-2 " v-if="item.display" >
                                                        <div class="d-flex">
                                                            <div class="w-15">
                                                                Prodcode
                                                            </div>
                                                            <div class="w-2">:</div>
                                                            <div class="w-15">
                                                                {{item.prod_code}}
                                                            </div>
                                                            <div class="w-15">
                                                                Wgt
                                                            </div>
                                                            <div class="w-2">:</div>
                                                            <div class="w-15">
                                                                {{$store.getters.convertToCurrencyUs(item.wgt)}}
                                                            </div>
                                                            <div class="w-15">
                                                                Length
                                                            </div>
                                                            <div class="w-2">:</div>
                                                            <div class="w-15">
                                                                {{$store.getters.convertToCurrencyUs(item.panjang)}}
                                                            </div>
                                                        </div>
                                                        <div class="d-flex">
                                                            <div class="w-15">
                                                                Batch ID
                                                            </div>
                                                            <div class="w-2">:</div>
                                                            <div class="w-15">
                                                                {{item.batch_id ? item.batch_id : '-'}}
                                                            </div>
                                                            <div class="w-15">
                                                                Add ID
                                                            </div>
                                                            <div class="w-2">:</div>
                                                            <div class="w-15">
                                                                {{item.add_id ? item.add_id : '-'}}
                                                            </div>
                                                            <div class="w-15">
                                                                Tor ID
                                                            </div>
                                                            <div class="w-2">:</div>
                                                            <div class="w-15">
                                                                {{item.tor_id ? item.tor_id : '-'}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </v-expand-transition>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="my-1 hr-line"></v-divider>
                                    </v-list-item-group>
                                </v-list>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>                  
        </v-row>
        <v-dialog
            v-model="dialog"
            persistent
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
            style="border-radius: 0 !important"
        >
            <v-card class="p-2" style="border-radius: 0 !important">
                <v-card-title class="text-center">
                    <v-row>
                        <v-col cols="12">
                            <div class="d-flex">
                                <div class="ml-5">
                                    Transaction
                                </div>
                                <v-spacer></v-spacer>
                                <v-btn
                                    class="mx-2"
                                    fab
                                    x-small
                                    color="#e83e8c;"
                                    @click="[dialog = false, clear()]"
                                >
                                    <v-icon dark>
                                        mdi-close
                                    </v-icon>
                                </v-btn>
                            </div>
                        </v-col>
                        <v-divider></v-divider>
                    </v-row>
                </v-card-title>
                <v-card-text>
                    <v-alert :color="type_alert" v-model="alert" dismissible class="white--text">{{ alert_message }}</v-alert>
                    <v-card outlined class="p-0 mb-2">
                        <v-container fluid class="p-3">
                            <v-row>
                                <v-col cols="12">
                                    <div class="d-flex">
                                        <h6 class="indigo-text m-0 primary--text"><v-icon color="primary" class="mr-3">mdi-page-layout-header</v-icon> Header: </h6>
                                        <v-spacer></v-spacer>
                                        <!-- <v-btn color="info" elevation="2" :disabled="disabled_header" @click="saveHeader()"><v-icon>mdi-content-save</v-icon> Save</v-btn> -->
                                    </div>
                                </v-col>
                                <v-col class="col-6" sm="6" md="2" lg="1">
                                    <v-text-field
                                        prepend-inner-icon="mdi-identifier"
                                        v-model="tr_id"
                                        dense
                                        solo
                                        label="TR ID"
                                        class="ma-0 pa-0 border-12"
                                        hide-details
                                        disabled
                                    ></v-text-field>
                                </v-col>
                                <v-col class="col-12" sm="6" md="2" lg="1">
                                    <v-menu
                                        ref="modal_trans_date"
                                        v-model="modal_trans_date"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        max-width="290px"
                                        min-width="auto"
                                        >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                            solo
                                            dense
                                            v-model="trans_date"
                                            label="Trans Date"
                                            persistent-hint
                                            prepend-inner-icon="mdi-calendar"
                                            v-bind="attrs"
                                            v-on="on"
                                            class="ma-0 pa-0 border-12"
                                            hide-details=true
                                            disabled
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="trans_date"
                                            no-title
                                            @input="modal_trans_date = false"
                                        ></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col class="col-6" sm="6" md="2" lg="2">
                                    <v-autocomplete
                                        prepend-inner-icon="mdi-fireplace-off"
                                        dense
                                        solo
                                        clearable
                                        v-model="mill"
                                        :items="mills"
                                        item-value="mill_id"
                                        item-text="mill_name"
                                        label="MILL ID"
                                        class="ma-0 pa-0 border-12"
                                        hide-details=true
                                        :disabled="disabled_mill"
                                        @change="(event) => [updateWarehouse(event), getMachine(event), getQuality(event), getProdCode(event), getAddId(event), getTorId(event), getPeriod(event)]"
                                    ></v-autocomplete>
                                </v-col>
                                <v-col class="col-6" sm="6" md="2" lg="2">
                                    <v-autocomplete
                                        prepend-inner-icon="mdi-map-marker"
                                        dense
                                        solo
                                        clearable
                                        v-model="warehouse"
                                        :items="warehouses"
                                        item-value="wh_id"
                                        item-text="descr"
                                        label="WH ID"
                                        class="ma-0 pa-0 border-12"
                                        hide-details=true
                                        :loading="loading_warehouse"
                                        :disabled="disabled_wh"
                                        @change="(event) => [checkWhtrans(event), getBin(event)]"
                                    ></v-autocomplete>
                                </v-col>
                                <v-col class="col-6" sm="6" md="2" lg="1">
                                    <v-autocomplete
                                        prepend-inner-icon="mdi-select-place"
                                        dense
                                        solo
                                        clearable
                                        v-model="bin_wh"
                                        :items="bin_whs"
                                        item-value="bin_id"
                                        item-text="descr"
                                        label="BIN ID *"
                                        class="ma-0 pa-0 border-12"
                                        hide-details=true
                                        disabled
                                    ></v-autocomplete>
                                </v-col>
                                <v-col class="col-6" sm="6" md="5" lg="5">
                                    <v-text-field
                                        prepend-inner-icon="mdi-middleware"
                                        v-model="remark"
                                        dense
                                        solo
                                        label="Remark"
                                        class="ma-0 pa-0 border-12"
                                        hide-details
                                        :disabled="disabled_header"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card>
                    <v-card outlined class="p-0 mb-2">
                        <v-container fluid class="p-3">
                            <v-row>
                                <v-col cols="12">
                                    <div class="d-flex">
                                        <h6 class="indigo-text m-0 primary--text"><v-icon color="primary" class="mr-3">mdi-filter</v-icon>Filter</h6>
                                        <v-spacer></v-spacer>
                                        <v-btn class=" mr-3" elevation="2">Clear</v-btn>
                                        <v-btn class="" color="error" elevation="2" @click="fetchDataWhBal()"><v-icon>mdi-refresh</v-icon> Refresh</v-btn>
                                    </div>
                                </v-col>
                                <v-col class="col-6" sm="6" md="2">
                                    <v-autocomplete
                                        prepend-inner-icon="mdi-slot-machine"
                                        clearable
                                        dense
                                        solo
                                        v-model="machine"
                                        :items="machines"
                                        item-value="mach_type"
                                        item-text="descr"
                                        label="Machine "
                                        class="ma-0 pa-0 border-12"
                                        hide-details
                                        @change="(event) => [updateGroupJenis(event), getTebal(event), getLebar(event), getColor(event)]"
                                    ></v-autocomplete>
                                </v-col>
                                <v-col class="col-6" sm="6" md="2" lg="1">
                                    <v-autocomplete
                                        prepend-inner-icon="mdi-group"
                                        clearable
                                        dense
                                        solo
                                        v-model="group"
                                        :items="groups"
                                        item-value="jenis"
                                        item-text="jenis"
                                        label="Group ID"
                                        class="ma-0 pa-0 border-12"
                                        hide-details
                                    ></v-autocomplete>
                                </v-col>
                                <v-col class="col-6" sm="6" md="2" lg="1">
                                    <v-autocomplete
                                        prepend-inner-icon="mdi-arrow-up-down"
                                        clearable
                                        dense
                                        solo
                                        v-model="thick"
                                        :items="thicks"
                                        item-value="tebal"
                                        item-text="tebal"
                                        label="Thickness"
                                        class="ma-0 pa-0 border-12"
                                        hide-details
                                    ></v-autocomplete>
                                </v-col>
                                <v-col class="col-6" sm="6" md="2" lg="1">
                                    <v-autocomplete
                                        prepend-inner-icon="mdi-arrow-left-right"
                                        clearable
                                        dense
                                        solo
                                        v-model="width"
                                        :items="widths"
                                        item-value="lebar"
                                        item-text="lebar"
                                        label="Width"
                                        class="ma-0 pa-0 border-12"
                                        hide-details
                                    ></v-autocomplete>
                                </v-col>
                                <v-col class="col-6" sm="6" md="2" lg="1">
                                    <v-autocomplete
                                        prepend-inner-icon="mdi-palette"
                                        clearable
                                        dense
                                        solo
                                        v-model="color"
                                        :items="colors"
                                        item-value="warna"
                                        item-text="namawarna"
                                        label="Color ID"
                                        class="ma-0 pa-0 border-12"
                                        hide-details
                                    ></v-autocomplete>
                                </v-col>
                                <v-col class="col-6" sm="6" md="2" lg="1">
                                    <v-text-field
                                        prepend-inner-icon="mdi-middleware"
                                        v-model="length"
                                        dense
                                        solo
                                        label="Length"
                                        class="ma-0 pa-0 border-12"
                                        hide-details
                                    ></v-text-field>
                                </v-col>
                                <v-col class="col-6" sm="6" md="2" lg="1">
                                    <v-autocomplete
                                        prepend-inner-icon="mdi-quality-high"
                                        clearable
                                        dense
                                        solo
                                        v-model="quality"
                                        :items="qualities"
                                        item-value="quality_id"
                                        item-text="quality_id"
                                        label="Quality"
                                        class="ma-0 pa-0 border-12"
                                        hide-details
                                    ></v-autocomplete>
                                </v-col>
                                <v-col class="col-6" sm="6" md="2" lg="1">
                                    <v-autocomplete
                                        prepend-inner-icon="mdi-identifier"
                                        clearable
                                        dense
                                        solo
                                        v-model="add_id"
                                        :items="add_ids"
                                        item-value="add_id"
                                        item-text="add_id"
                                        label="ADD ID"
                                        class="ma-0 pa-0 border-12"
                                        hide-details
                                    ></v-autocomplete>
                                </v-col>
                                <v-col class="col-6" sm="6" md="2" lg="1">
                                    <v-autocomplete
                                        prepend-inner-icon="mdi-identifier"
                                        clearable
                                        dense
                                        solo
                                        v-model="tor_id"
                                        :items="tor_ids"
                                        item-value="tor_id"
                                        item-text="tor_id"
                                        label="Tora ID"
                                        class="ma-0 pa-0 border-12"
                                        hide-details
                                    ></v-autocomplete>
                                </v-col>
                                <v-col class="col-6" sm="6" md="2">
                                    <v-autocomplete
                                        prepend-inner-icon="mdi-bag-personal"
                                        clearable
                                        dense
                                        solo
                                        v-model="product_code"
                                        :items="product_codes"
                                        item-value="prod_code"
                                        item-text="prod_code"
                                        label="Product Code"
                                        class="ma-0 pa-0 border-12"
                                        hide-details
                                    ></v-autocomplete>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card>
                    <v-card outlined class="p-0 mb-2">
                        <v-container fluid class="p-3">
                            <v-row>
                                <v-col cols="12">
                                    <h6 class="indigo-text m-0 primary--text"><v-icon color="primary" class="mr-3">mdi-table</v-icon> Result Filter Item: </h6>
                                </v-col>
                                <v-col cols="12">
                                    <v-data-table
                                        dense
                                        :headers="headers"
                                        :items="wh_transes"
                                        class="elevation-1"
                                        :items-per-page="-1"
                                        :footer-props="{
                                            'items-per-page-options': [10, 20, 30, 40, 50, -1]
                                        }"
                                        :loading="$store.state.overlay"
                                        height="200"
                                        fixed-header
                                        :divider="true"
                                        :light="true"
                                        :item-class="tr_datatable_outstanding"
                                        hide-default-footer
                                        @click:row="showDetailRequest"
                                    >    
                                        <template v-slot:[`item.width`]="{ item }">
                                            {{ $store.getters.convertToCurrencyNoFrac(item.width) }}
                                        </template> 
                                        <template v-slot:[`item.wgt`]="{ item }">
                                            {{ $store.getters.convertToCurrencyUs(item.wgt) }}
                                        </template> 
                                        <template v-slot:[`item.thick`]="{ item }">
                                            {{ $store.getters.convertToCurrencyUs(item.thick) }}
                                        </template> 
                                        <template v-slot:[`item.panjang`]="{ item }">
                                            {{ $store.getters.convertToCurrencyUs(item.panjang) }}
                                        </template>
                                    </v-data-table>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card>
                    <v-card outlined class="p-0 mb-2">
                        <v-container fluid class="p-3">
                            <v-row>
                                <v-col cols="12">
                                    <div class="d-flex">
                                        <h6 class="indigo-text m-0 primary--text"><v-icon color="primary" class="mr-3">mdi-warehouse</v-icon>Add Item</h6>
                                        <v-spacer></v-spacer>
                                        <v-btn class="" color="success" elevation="2" @click="saveHeader"><v-icon>mdi-plus</v-icon> Add Item</v-btn>
                                    </div>
                                </v-col>
                                <v-col class="col-6" sm="6" md="3">
                                    <p class="ma-0 pa-0" style="font-weight:600">Product Description</p>
                                    <v-text-field
                                        prepend-inner-icon="mdi-package"
                                        v-model="product"
                                        dense
                                        solo
                                        class="ma-0 pa-0 border-12"
                                        hide-details
                                    ></v-text-field>
                                </v-col>
                                <v-col class="col-6" sm="6" md="2" lg="1">
                                    <p class="ma-0 pa-0" style="font-weight:600">Max Qty</p>
                                    <v-text-field
                                        v-model="max_qty"
                                        dense
                                        solo
                                        class="ma-0 pa-0 border-12"
                                        hide-details
                                        disabled
                                    ></v-text-field>
                                </v-col>
                                <v-col class="col-6" sm="6" md="2" lg="1">
                                    <p class="ma-0 pa-0" style="font-weight:600">Qty</p>
                                    <div class="input-group spinner number mt-1">
                                        <button class="btn error v-btn p-1" type="button" @click="minQty()"> 
                                            <v-icon small color="white">mdi-minus</v-icon>
                                        </button>
                                        <input class="form-control" type="text" name="quantity" min="1" :max="max_qty" v-model="qty_oh" style="text-align:center; font-size: 12px;" @change="UpdateQty()">
                                        <button class="btn success v-btn p-1" type="button" @click="addQty()">
                                            <v-icon small color="white">mdi-plus</v-icon>
                                        </button>
                                    </div>
                                </v-col>
                                <v-col class="col-6" sm="6" md="3" lg="2">
                                    <p class="ma-0 pa-0" style="font-weight:600">Category Defect</p>
                                    <v-autocomplete
                                        prepend-inner-icon="mdi-align-horizontal-left"
                                        clearable
                                        dense
                                        solo
                                        v-model="reason"
                                        :items="reasons"
                                        item-value="id"
                                        :item-text="item => item.groupp.trim() +' - '+ item.descr.trim()"
                                        label="Category"
                                        class="ma-0 pa-0 border-12"
                                        hide-details
                                    ></v-autocomplete>
                                </v-col>
                                <v-col class="col-6" sm="6" md="4" lg="5">
                                    <p class="ma-0 pa-0" style="font-weight:600">Remark</p>
                                    <v-text-field
                                        prepend-inner-icon="mdi-middleware"
                                        v-model="remark_prod"
                                        dense
                                        solo
                                        label="Remark"
                                        class="ma-0 pa-0 border-12"
                                        hide-details
                                    ></v-text-field>
                                    
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card>
                    <v-card outlined class="p-0 mb-2">
                        <v-container fluid class="p-3">
                            <v-row>
                                <v-col cols="12">
                                    <h6 class="indigo-text m-0 primary--text"><v-icon color="primary" class="mr-3">mdi-table</v-icon> Result Transaction: </h6>
                                </v-col>
                                <v-col cols="12">
                                    <v-data-table
                                        dense
                                        :headers="header_wh_details"
                                        :items="wh_details"
                                        class="elevation-1"
                                        :items-per-page="-1"
                                        :footer-props="{
                                            'items-per-page-options': [10, 20, 30, 40, 50, -1]
                                        }"
                                        :loading="$store.state.overlay"
                                        height="400"
                                        fixed-header
                                        :divider="true"
                                        :light="true"
                                        :item-class="tr_datatable"
                                        hide-default-footer
                                    >    
                                        <template v-slot:[`item.panjang`]="{ item }">
                                            {{ $store.getters.convertToCurrencyUs(item.panjang) }}
                                        </template> 
                                        <template v-slot:[`item.qty`]="{ item }">
                                            {{ $store.getters.convertToCurrencyNoFrac(item.qty) }}
                                        </template> 
                                        <template v-slot:[`item.wgt`]="{ item }">
                                            {{ $store.getters.convertToCurrencyUs(item.wgt) }}
                                        </template> 
                                        <template v-slot:[`item.thick`]="{ item }">
                                            {{ $store.getters.convertToCurrencyUs(item.thick) }}
                                        </template> 
                                        <template v-slot:[`item.actions`]="{ item }">
                                            <v-icon color="red" @click="deleteWhDetail(item)">
                                                mdi-delete
                                            </v-icon>
                                        </template> 
                                    </v-data-table>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="dialog_delete_wh_detail"
            persistent
            max-width="600px"
            hide-overlay
            transition="dialog-bottom-transition"
        >
            <v-card class="rounded-l">
                <v-card-title class="text-center mb-0 pb-0">
                    <v-row>
                        <v-col cols="12">
                            <div class="d-flex">
                                <div class="ml-5">
                                    Delete Item
                                </div>
                                <v-spacer></v-spacer>
                                <v-btn
                                    class="mx-2"
                                    fab
                                    x-small
                                    color="#e83e8c;"
                                    @click="[dialog_delete_wh_detail = false]"
                                >
                                    <v-icon dark>
                                        mdi-close
                                    </v-icon>
                                </v-btn>
                            </div>
                        </v-col>
                        <v-divider></v-divider>
                    </v-row>
                </v-card-title>
                <v-card-text class="ma-0">
                    <v-container class="fill-height">
                    <v-row align="center" justify="center">
                        <v-col cols="12" xs="12" sm="2" md="2">
                            <v-icon x-large color="success" class="text-center">
                                mdi-check
                            </v-icon>
                        </v-col>
                    </v-row> 
                    <v-row align="center" justify="center">
                        <v-col cols="12" xs="12" sm="8" md="10">
                            <h6 class="text-center">
                            Are you sure delete this Item Transaction {{ wh_detail ? wh_detail.NamaBarang : ''}}?
                            </h6>
                            <p class="text-center">if yes, please click Yes. if no, please click No</p>
                        </v-col>
                    </v-row> 
                    </v-container>
                </v-card-text>
                <v-card-actions class="text-center">
                    <v-container fluid> 
                        <v-row justify="center">
                            <v-col cols="12" xs="12" sm="10" md="10">
                            <v-btn class="border-12 mt-0 mr-3" style="padding: 19px;" small color="error" elevation="2" @click="[dialog_delete_wh_detail = false, wh_detail = null]">No <v-icon class="ml-3">mdi-close</v-icon></v-btn>
                            <v-btn class="border-12 mt-0" style="padding: 19px;" small color="success" elevation="2" @click="deleteItem()">Yes <v-icon class="ml-3">mdi-check</v-icon></v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>
<script>
import {backendApi} from "@/backend-api/backend-api-sr"

export default {
    data() {
        return {
            tabs: [
                {
                    text: 'Transaction',
                    value: 'tab-1'
                },
                {
                    text: 'List Transaction',
                    value: 'tab-2'
                }
            ],
            tab: 'tab-1',
            items: ['02'],
            values: ['foo', 'bar'],
            shifts: [1,2,3],
            shift: '',
            value: '02',
            breadcumbs: [
                {
                text: 'KKA',
                disabled: false,
                href: '/admin/kka',
                },
                {
                text: 'KKA Production',
                disabled: false,
                href: '/admin/kka/production',
                },
                {
                text: 'Finish Good Transaction',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
                {
                text: 'Hold Stock',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
            ],
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            date_from: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            trans_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            modal_trans_date: false,
            modal: false,
            datetime: new Date(),
            date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            menu: false,
            modal: false,
            menu2: false,
            tr_id: '',
            periode: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 7).replace(/-/g,""),
            error: true,
            show: false,
            errors: [],
            loading: false,
            loading_wh_transes: false,
            mills: [],
            mill: '',
            warehouse: '',
            warehouses: [],
            warehouse_dest: '',
            warehouses_dest: [],
            timeout: 7500,
            disabled : 1,
            display: 'none',
            displayDest: 'none',
            searchItem:'',
            order_flags: [
                {
                    value: 'N',
                    text: 'Hold Stock'
                },
                {
                    value: 'H',
                    text: 'UnHold Stock'
                }
            ],
            order_flag: 'N',
            headers: [
                { text: 'Prod Code', value: 'prod_code', width:120, align: 'left' },
                { text: 'Description', value: 'NamaBarang', width:200, align: 'left' },
                { text: 'Qty on Hand', value: 'qty_oh', width:150, align: 'right' },          
                { text: 'Wgt', value: 'wgt_oh', width:100, align: 'right' },
                { text: 'Color', value: 'NamaWarna', width:120, align: 'right' },
                { text: 'Length', value: 'panjang', width:100, align: 'right' },
                { text: 'Add ID', value: 'add_id', width:100, align: 'left' },
                { text: 'TOR ID', value: 'tor_id', width:100, align: 'left' },
                { text: 'ACP ID', value: 'acp_id', width:100, align: 'left' },
                { text: 'Batch ID', value: 'batch_id', width:100, align: 'left' },
                { text: 'Order ID', value: 'order_id', width:100, align: 'left' },
                { text: 'Item Num', value: 'item_num', width:100, align: 'left' },
            ],
            totalItems: 10,
            options: {},
            wh_ohs: [],
            header_wh_transes: [
                { text: '',  value: 'actions', sortable: false, width:100, align: 'left' },
                {
                    text: 'TR ID',
                    value: 'tr_id',
                    width:150, align: 'left'
                },
                { text: 'Periode', value: 'period', width:150, align: 'left' },
                { text: 'TR Date', value: 'tr_date', width:150, align: 'left' },
                { text: 'Mill ID', value: 'mill_name', width:150, align: 'left' },
                { text: 'Warehouse', value: 'whouse_name', width:200, align: 'left' },
                { text: 'Bin', value: 'bin_id', width:70, align: 'left' },
                { text: 'Remark', value: 'remark', width:250 },
                { text: 'Operator', value: 'operator', width:150, align: 'left' },
            ],
            wh_transes: [],
            transactions: [],
            searchItemTrans: '',
            dataWhOh: {},
            remark: '',
            remark_dtl_whoh: '',
            qty_oh: 0,
            orders: [],
            order: '',
            item_nums: [],
            item_num: '',
            loading4: false,
            loader: null,
            tab: null,
            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
            data_var: {
                entity_id : 'KKA',
                appl_id : 'MILLPRODUCTION'
            },
            mill_id: '',
            dateProps: {
                headerColor: 'blue',
                allowedDates: this.allowedDates
            },
            loc_id: '',
            disabled_search: false,
            status_req: true,
            disabled_wh_dest: true,
            machines: [],
            machine: '',
            groups: [],
            group: '',
            thicks: [],
            thick: '',
            widths: [],
            width: '',
            colors: [],
            color: '',
            lengths: [],
            length: '',
            qualities: [],
            quality: '',
            product_codes: [],
            product_code: '',
            add_ids: [],
            add_id: '',
            tor_ids: [],
            tor_id: '',
            bin_whs: [],
            bin_wh: '',
            bin_wh_dests: [],
            bin_wh_dest: '',
            group_user: '',
            operator: '',
            loading_warehouse: false,
            cartEmpty: true,
            carts: [],
            periode_curr: null,
            disabled_mill: false,
            disabled_wh: false,
            active: 'trans',
            itemDetail: null,
            itemDetails: [],
            modal_to: false,
            date_to: '',
            dialog: false,
            product: '',
            max_qty: 0,
            qty_oh: 0,
            remark_prod: '',
            header_wh_details: [
                { text: '', value: 'actions', width:50, align: 'left' },
                { text: 'Mill ID', value: 'mill_id', width:120, align: 'left' },
                { text: 'TR ID', value: 'tr_id', width:120, align: 'left' },
                { text: 'Warehouse', value: 'wh_id', width:200, align: 'left' },
                { text: 'Prod Code', value: 'prod_code', width:120, align: 'left' },
                { text: 'Description', value: 'NamaBarang', width:200, align: 'left' },
                { text: 'Qty', value: 'qty', width:150, align: 'right' },          
                { text: 'Wgt', value: 'wgt', width:100, align: 'right' },
                { text: 'Length', value: 'panjang', width:100, align: 'right' },
                { text: 'Add ID', value: 'add_id', width:100, align: 'left' },
                { text: 'TOR ID', value: 'tor_id', width:100, align: 'left' },
                { text: 'ACP ID', value: 'acp_id', width:100, align: 'left' },
                { text: 'Batch ID', value: 'batch_id', width:120, align: 'left' },
                { text: 'Group Defect', value: 'groupp_non_prime', width:180, align: 'left' },
                { text: 'Category Defect', value: 'category_non_prime', width:180, align: 'left' },
                { text: 'Remark', value: 'remark', width:200, align: 'left' },
            ],
            wh_details: [],
            disabled_header: false,
            env_mill: '',
            env_wh: '',
            type_alert: 'success',
            alert: false,
            alert_message: '',
            detailOutstanding: null,
            wh_detail: null,
            dialog_delete_wh_detail: false,
            accessTrans: 'N',
            reasons: [],
            reason: '',
            mill_id: '',
            warehouse_id: ''
        }
    },
    async mounted(){
        this.$store.dispatch('setOverlay', true)
        await this.getEnvConf()
        await this.getMills()
        await this.getReason()
        this.$store.dispatch('setOverlay', false)
    },
    methods:{
        tr_datatable(item) {
            var rowClass = 'tr_datatable'
            return rowClass;
        },
        tr_datatable_outstanding(item) {
            var rowClass = 'tr_datatable';
            if (this.detailOutstanding) {
                if(this.detailOutstanding.prod_code === item.prod_code && this.detailOutstanding.panjang === item.panjang && this.detailOutstanding.add_id === item.add_id && this.detailOutstanding.tor_id === item.tor_id && this.detailOutstanding.acp_id === item.acp_id && this.detailOutstanding.quality_id === item.quality_id && this.detailOutstanding.batch_id === item.batch_id){
                    rowClass = 'tr_choice'
                } else {
                    rowClass = 'tr_datatable'
                }
            } else {
                rowClass = 'tr_datatable'
            }
            return rowClass;
        },
        async getEnvConf(){
            this.$store.dispatch('setOverlay', true)

            const respDataMill = await backendApi.fetchCore(`/api/get_env_var_conf?entity_id=KKA&appl_id=WEBKKA&var_id=MILLID&groupid=${this.$store.state.kka.group_id}`, null, false, false, false)            

            if (respDataMill.data.data != null || respDataMill.data.data != '') {  
                this.mill = respDataMill.data.data      
                this.mill_id = respDataMill.data.data      
                this.disabled_mill = this.mill ? true : false  
                this.env_mill = this.mill ? 'Y' : 'N'
                if (this.mill) {
                    await this.updateWarehouse(this.mill)
                    await this.updateWarehouseFilter(this.mill_id)
                    await this.getMachine(this.mill_id) 
                    await this.getQuality(this.mill_id) 
                    await this.getProdCode(this.mill_id) 
                    await this.getAddId(this.mill_id) 
                    await this.getTorId(this.mill_id)
                    await this.getPeriod(this.mill_id)
                } 
            }

            const respDataWH = await backendApi.fetchCore(`/api/get_env_var_conf?entity_id=KKA&appl_id=WEBKKA&var_id=WHID&groupid=${this.$store.state.kka.group_id}`, null, false, false, false)  
            
            if (respDataWH.data.data != null || respDataWH.data.data != '') {  
                this.warehouse = respDataWH.data.data      
                this.warehouse_id = respDataWH.data.data      

                this.disabled_wh = this.warehouse ? true : false  
                this.env_wh = this.warehouse ? 'Y' : 'N'
                if (this.warehouse) {
                    await this.updateWarehouse(this.warehouse)
                    await this.updateWarehouseFilter(this.warehouse)
                    await this.checkWhtrans(this.warehouse) 
                    await this.getBin(this.warehouse)
                } 
            }

            const respDataAccess = await backendApi.fetchCore(`/api/get_env_var_conf?entity_id=KKA&appl_id=WEBKKA&var_id=HOLD&groupid=${this.$store.state.kka.group_id}`, null, false, false, false)

            if (respDataAccess.data.data != null || respDataAccess.data.data != '' || respDataAccess.data.data != 'null') { 
                this.accessTrans = respDataAccess.data.data
            } else {
                this.accessTrans = 'N'
            }

            this.$store.dispatch('setOverlay', false)


        },
        async clear(){
            this.$store.dispatch('setOverlay', true)
            // this.date_from = ''
            // this.mill = ''
            this.warehouse = ''
            await this.updateWarehouseFilter(this.mill)
            await this.fetchData()
            this.tr_id = ''
            this.machine = ''
            this.group = ''
            this.thick = ''
            this.width = ''
            this.color = ''
            this.length = ''
            this.quality = ''
            this.add_id = ''
            this.tor_id = ''
            this.product_code = ''
            this.wh_transes = []
            this.cartEmpty = true
            this.carts = []

            this.disabled_mill = this.env_mill === 'Y' ? true : false
            this.disabled_wh = this.env_wh === 'Y' ? true : false
            
            this.itemDetails = []
            this.itemDetail = null
            this.wh_details = []
            this.product = ''
            this.max_qty = 0
            this.qty_oh = 0
            this.reason = ''
            this.remark_prod = ''
            this.$store.dispatch('setOverlay', false)
        },
        async getMills(){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/kka/mill?mill_id=${this.mill ? this.mill : ''}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.mills = res.data.data
            });
        },
        async getReason(){
            await axios.get(`${process.env.VUE_APP_URL}/api/v3/kka/hold-stock/getReason`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.reasons = res.data.data
            });
        },
        async checkWhtrans(event){
            if (event == '' || event == null) {
                this.warehouse = ''
            } else {
                this.$store.dispatch('setOverlay', true)

                var reqBody = {
                    'mill_id': this.mill ? this.mill : '',
                    'wh_id': this.warehouse ? this.warehouse : '',
                    'bin_id': this.bin_wh ? this.bin_wh : '02',
                    'tr_date': this.trans_date ? new Date(this.trans_date).toISOString().substr(0, 10) : '',
                    'tr_type': 'N',
                }

                const respData = await backendApi.fetchCore('/api/v3/kka/hold-stock/checkWhTrans', reqBody, false, false, false)

                if (respData.status === 200) {
                    this.$store.dispatch('setOverlay', false)
                    console.log(respData.data);
                    if (respData.data.data) {
                        this.tr_id = respData.data.data.tr_id ? respData.data.data.tr_id.trim() : ''
                        this.remark = respData.data.data.remark ? respData.data.data.remark : ''
                        this.disabled_header = true
                        this.disabled_mill = true
                        this.disabled_wh = true
                        await this.showTransaction()
                    } else {
                        this.tr_id = ''
                        this.disabled_header = false
                        this.disabled_mill = this.env_mill === 'Y' ? true : false
                        this.disabled_wh = this.env_wh === 'Y' ? true : false
                    }
                }

            }
        },
        async updateWarehouseFilter(event){
            if (event == '' || event == null) {
                this.warehouse = ''
            } else {
                this.warehouses = []
                this.loading_warehouse = true
                await axios.get(`${process.env.VUE_APP_URL}/api/master/kka/warehouse?mill_id=${this.mill_id ? this.mill_id : ''}&active_flag=Y`, { 
                    headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                })
                .then(res => {
                    this.loading_warehouse = false
                    this.warehouses = res.data
                });
            }
        },
        async updateWarehouse(event){
            if (event == '' || event == null) {
                this.warehouse = ''
            } else {
                this.warehouses = []
                this.loading_warehouse = true
                await axios.get(`${process.env.VUE_APP_URL}/api/master/kka/warehouse?mill_id=${this.mill ? this.mill : ''}&wh_id=${this.warehouse ? this.warehouse : ''}&active_flag=Y`, { 
                    headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                })
                .then(res => {
                    this.loading_warehouse = false
                    this.warehouses = res.data
                });
            }
        },
        async getBin(event){
            if (event == '' || event == null) {
                this.bin_wh = ''
            } else {
                this.bin_wh = '02'
                await axios.get(`${process.env.VUE_APP_URL}/api/master/kka/bin_id?mill_id=${this.mill}&wh_id=${this.warehouse ? this.warehouse : ''}&status=Y`, { 
                    headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                })
                .then(res => {
                    this.bin_whs = res.data
                });
            }
        },
        async getMachine(event){
            if (event == '' || event == null) {
                this.machine = ''
            } else {
                await axios.get(`${process.env.VUE_APP_URL}/api/master/kka/machine_brg_jadi?mill_id=${this.mill ? this.mill : ''}`, { 
                    headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                })
                .then(res => {
                    this.machines = res.data
                });
            }
        },
        async getQuality(event){
            if (event == '' || event == null) {
                this.quality = ''
            } else {
                await axios.get(`${process.env.VUE_APP_URL}/api/master/kka/quality?mill_id=${this.mill ? this.mill : ''}`, { 
                    headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                })
                .then(res => {
                    this.qualities = res.data
                });
            }
        },
        async getProdCode(event){
            if (event == '' || event == null) {
                this.product_code = ''
            } else {
                await axios.get(`${process.env.VUE_APP_URL}/api/master/kka/prod_code?mill_id=${this.mill ? this.mill : ''}`, { 
                    headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                })
                .then(res => {
                    this.product_codes = res.data
                });
            }
        },
        async getAddId(event){
            if (event == '' || event == null) {
                this.add_id = ''
            } else {
                await axios.get(`${process.env.VUE_APP_URL}/api/master/kka/add_id?mill_id=${this.mill ? this.mill : ''}`, { 
                    headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                })
                .then(res => {
                    this.add_ids = res.data
                });
            }
        },
        async getTorId(event){
            if (event == '' || event == null) {
                this.tor_id = ''
            } else {
                await axios.get(`${process.env.VUE_APP_URL}/api/master/kka/tor_id?mill_id=${this.mill ? this.mill : ''}`, { 
                    headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                })
                .then(res => {
                    this.tor_ids = res.data
                });
            }
        },
        async updateGroupJenis(event){
            if (event == '' || event == null) {
                this.group = ''
            } else {
                await axios.get(`${process.env.VUE_APP_URL}/api/master/kka/jenis_brg_jadi?mach_type=${this.machine ? this.machine : ''}`, { 
                    headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                })
                .then(res => {
                    this.groups = res.data
                });
            }

        },
        async getTebal(event){
            if (event == '' || event == null) {
                this.thick = ''
            } else {
                await axios.get(`${process.env.VUE_APP_URL}/api/master/kka/tebal_brg_jadi?mach_type=${this.machine ? this.machine : ''}`, { 
                    headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                })
                .then(res => {
                    this.thicks = res.data
                });
            }
        },
        async getLebar(event){
            if (event == '' || event == null) {
                this.width = ''
            } else {
                await axios.get(`${process.env.VUE_APP_URL}/api/master/kka/lebar_brg_jadi?mach_type=${this.machine ? this.machine : ''}`, { 
                    headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                })
                .then(res => {
                    this.widths = res.data
                });
            }
        },
        async getColor(event){
            if (event == '' || event == null) {
                this.color = ''
            } else {
                await axios.get(`${process.env.VUE_APP_URL}/api/master/kka/color_brg_jadi?mach_type=${this.machine ? this.machine : ''}`, { 
                    headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                })
                .then(res => {
                    this.colors = res.data
                });
            }
        },
        async getPeriod(event){
            if (event == '' || event == null) {
                this.periode_curr = ''
            } else {
                var reqBody = {
                    'mill_id': this.mill ? this.mill : ''
                }
                const respData = await backendApi.fetchCore('/api/v3/kka/hold-stock/getPeriod', reqBody, false, false, false)
                if (respData.status === 200) {
                    this.periode_curr = respData.data
                }
            }
        },
        async fetchDataWhBal(){
            this.$store.dispatch('setOverlay', true)
            
            if (this.mill == '' || this.mill == null || this.warehouse == '' || this.warehouse == null) {
                this.$store.dispatch('setOverlay', false)
                this.alert = true
                this.type_alert = 'warning'
                this.alert_message = "Please fill Mill and Warehouse"
                return false
            }

            var period = this.date_from ? new Date(this.date_from).toISOString().substr(0, 7).replace(/-/g,"") : ''

            var reqBody = {
                'period': period,
                'mill_id': this.mill ? this.mill : '',
                'wh_id': this.warehouse ? this.warehouse : '',
                'bin_id': this.bin_wh ? this.bin_wh : '02',
                'mach_type': this.machine ? this.machine : '',
                'jenis': this.group ? this.group : '',
                'tebal': this.thick ? this.thick : '',
                'warna': this.color ? this.color : '',
                'lebar': this.width ? this.width : '',
                'quality_id': this.quality ? this.quality : '',
                'panjang': this.length ? this.length : '',
                'prod_code': this.product_code ? this.product_code : '',
                'add_id': this.add_id ? this.add_id : '',
                'tor_id': this.tor_id ? this.tor_id : '',
                'ord_flag': this.order_flag ? this.order_flag : 'N',
                'tr_date': this.date_from ? new Date(this.date_from).toISOString().substr(0, 10) : '',
                'tr_type': this.order_flag === 'N' ? 'TH' : 'UH',
            }

            const respData = await backendApi.fetchCore('/api/v3/kka/hold-stock', reqBody, false, false, false)

            if (respData.status === 200) {
                this.wh_transes = respData.data
                this.$store.dispatch('setOverlay', false)
            } else {
                this.$store.dispatch('setOverlay', false)
            }
        },
        async showDetailRequest(row){
            this.detailOutstanding = row
            this.product = row.NamaBarang
            this.max_qty = parseInt(row.qty_oh)
            this.qty_oh = parseInt(row.qty_oh)
        },
        async addDialog(){
            if (this.env_mill == 'Y' || this.env_wh == 'Y') {
                await this.getEnvConf()
            }
            this.dialog = true
            this.disabled_header = false
        },
        async fetchData(){
            this.$store.dispatch('setOverlay', true)
            
            if (this.date_from == '' || this.date_from == null) {
                this.$store.dispatch('setOverlay', false)
                this.disabled_search = false
                this.disabled_mill = this.env_mill === 'Y' ? true : false
                this.disabled_wh = this.env_wh === 'Y' ? true : false
                this.snackbar = {
                    color: "warning",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Warning",
                    text: "Please fill Trans Date",
                    visible: true
                };
                return false
            }

            var period = this.date_from ? new Date(this.date_from).toISOString().substr(0, 7).replace(/-/g,"") : ''

            var reqBody = {
                'period': period,
                'mill_id': this.mill_id ? this.mill_id : '',
                'wh_id': this.warehouse_id ? this.warehouse_id : '',
                'bin_id': this.bin_wh ? this.bin_wh : '02',
                'mach_type': this.machine ? this.machine : '',
                'jenis': this.group ? this.group : '',
                'tebal': this.thick ? this.thick : '',
                'warna': this.color ? this.color : '',
                'lebar': this.width ? this.width : '',
                'quality_id': this.quality ? this.quality : '',
                'panjang': this.length ? this.length : '',
                'prod_code': this.product_code ? this.product_code : '',
                'add_id': this.add_id ? this.add_id : '',
                'tor_id': this.tor_id ? this.tor_id : '',
                'ord_flag': this.order_flag ? this.order_flag : 'N',
                'tr_date': this.date_from ? new Date(this.date_from).toISOString().substr(0, 10) : '',
                'tr_type': this.order_flag === 'N' ? 'TH' : 'UH',
            }
            this.wh_transes = []
            this.transactions = []
            // const respData = await backendApi.fetchCore('/api/v3/kka/hold-stock', reqBody, false, false, false)
            const respDataList = await backendApi.fetchCore('/api/v3/kka/hold-stock/listTransaction', reqBody, false, false, false)

            if (respDataList.status === 200) {
                // this.wh_transes = respData.data
                this.transactions = respDataList.data
                this.$store.dispatch('setOverlay', false)
            } else {
                this.$store.dispatch('setOverlay', false)
            }
        },
        loadMore(detail, index){
        },
        addQty(){
            var qty = parseInt(this.qty_oh) + 1
            if (qty <= parseInt(this.max_qty)) {
                this.qty_oh = qty
            }
        },
        minQty(){
            var qty = parseInt(this.qty_oh) - 1
            if (qty > 0) {
                this.qty_oh = qty
            }
        },
        UpdateQty(){
            if (parseInt(this.qty_oh) <= parseInt(this.max_qty) && parseInt(this.qty_oh) > 0) {
                this.qty_oh = this.qty_oh
            } else {
                this.qty_oh = this.max_qty
            }
        },
        async saveHeader(){
            this.$store.dispatch('setOverlay', true)

            if (this.trans_date == '' || this.trans_date == null || this.mill == '' || this.mill == null || this.warehouse == '' || this.warehouse == null || this.detailOutstanding == null || this.detailOutstanding == '' || this.qty_oh == '' || this.qty_oh == null || this.qty_oh == 0) {
                this.$store.dispatch('setOverlay', false)
                this.alert = true
                this.type_alert = 'warning'
                this.alert_message = "Please fill Trans Date, Mill, Warehouse and choice Item in Table Result Filter Item"
                return false
            }

            var period = this.date_from ? new Date(this.date_from).toISOString().substr(0, 7).replace(/-/g,"") : ''

            if (this.periode_curr) {
                if (this.periode_curr.curr_period.trim() != period) {
                    this.$store.dispatch('setOverlay', false)
                    this.disabled_search = false
                    this.alert = true
                    this.type_alert = 'warning'
                    this.alert_message = "Maaf Belum di closing"
                    return false
                }
            } 

            var reqBody = {
                'period': period,
                'mill_id': this.mill ? this.mill : '',
                'wh_id': this.warehouse ? this.warehouse : '',
                'bin_id': this.bin_wh ? this.bin_wh : '02',
                'ord_flag': this.order_flag ? this.order_flag : 'N',
                'tr_date': this.trans_date ? new Date(this.trans_date).toISOString().substr(0, 10) : '',
                'tr_type': 'N',
                'remark' : this.remark ? this.remark : ''
            }

            const respData = await backendApi.fetchCore('/api/v3/kka/hold-stock/storeHeader', reqBody, false, false, false)

            if (respData.status === 200) {
                this.tr_id = respData.data
                this.disabled_header = true
                this.disabled_mill = true
                this.disabled_wh = true
                await this.saveHoldStock()
            }else {
                this.$store.dispatch('setOverlay', false)
                this.alert = true
                this.type_alert = 'error'
                this.alert_message = respData.data
            }
        },
        async saveHoldStock(){            
            if (this.detailOutstanding == null || this.detailOutstanding == '' || this.qty_oh == '' || this.qty_oh == null || this.qty_oh == 0 || this.tr_id == '' || this.tr_id == null) {
                this.$store.dispatch('setOverlay', false)
                this.alert = true
                this.type_alert = 'warning'
                this.alert_message = "Please choice Item in Table Result Filter Item and fill TR ID"
                return false
            }

            var reqBody = {
                period : this.trans_date ? new Date(this.trans_date).toISOString().substr(0, 7).replace(/-/g,"") : '',
                mill_id: this.mill ? this.mill : '',
                tr_id: this.tr_id,
                BarangJadiId: this.detailOutstanding.BarangJadiId ? this.detailOutstanding.BarangJadiId : '',
                prod_code: this.detailOutstanding.prod_code ? this.detailOutstanding.prod_code : '',
                add_id: this.detailOutstanding.add_id ? this.detailOutstanding.add_id : '',
                tor_id: this.detailOutstanding.tor_id ? this.detailOutstanding.tor_id : '',
                acp_id: this.detailOutstanding.acp_id ? this.detailOutstanding.acp_id : '',
                panjang: this.detailOutstanding.panjang ? this.detailOutstanding.panjang : '',
                quality_id: this.detailOutstanding.quality_id ? this.detailOutstanding.quality_id : '',
                ord_flag: 'N',
                batch_id: this.detailOutstanding.batch_id ? this.detailOutstanding.batch_id : '',
                wh_id: this.detailOutstanding.wh_id ? this.detailOutstanding.wh_id : '',
                bin_id: this.detailOutstanding.bin_id ? this.detailOutstanding.bin_id : '02',
                qty_oh: parseInt(this.qty_oh),
                remark: this.remark_prod ? this.remark_prod : '',
                cat_nonprime: this.reason ? this.reason : '',
            }
            const respData = await backendApi.fetchCore('/api/v3/kka/hold-stock/store', reqBody, false, false, false)

            if (respData.status === 200) {
                this.$store.dispatch('setOverlay', false)
                this.detailOutstanding = null
                this.qty_oh = 0
                this.max_qty = 0
                this.product = ''
                this.reason = ''
                this.remark_prod = ''
                this.alert = true
                this.type_alert = 'success'
                this.alert_message = "Successfully add item"
                await this.fetchDataWhBal()
                await this.showTransaction()
            } else {
                this.$store.dispatch('setOverlay', false)
                this.alert = true
                this.type_alert = 'error'
                this.alert_message = respData.data
            }
        },
        async showTransaction(){
            this.$store.dispatch('setOverlay', true)
            var reqBody = {
                'mill_id': this.mill ? this.mill : '',
                'tr_id': this.tr_id ? this.tr_id : ''
            }
            const respData = await backendApi.fetchCore('/api/v3/kka/hold-stock/listShowTransaction', reqBody, false, false, false)
            if (respData.status === 200) {
                this.wh_details = respData.data
                this.$store.dispatch('setOverlay', false)
            }
        },
        async showItem(item){
            this.itemDetails = []
            this.itemDetail = null
            this.$store.dispatch('setOverlay', true)
            var reqBody = {
                'mill_id': item ? item.mill_id : '',
                'tr_id': item ? item.tr_id : ''
            }
            const respData = await backendApi.fetchCore('/api/v3/kka/hold-stock/listShowTransaction', reqBody, false, false, false)
            if (respData.status === 200) {
                this.$store.dispatch('setOverlay', false)
                this.itemDetail = item

                for (let index = 0; index < respData.data.length; index++) {
                    const element = respData.data[index];
                    this.itemDetails.push(
                        {
                            BarangJadiId: element.BarangJadiId,
                            NamaBarang: element.NamaBarang,
                            acp_id: element.acp_id,
                            add_id: element.add_id,
                            batch_id: element.batch_id,
                            bin_id: element.bin_id,
                            ord_flag: 'N',
                            panjang: element.panjang,
                            period: element.period,
                            prod_code: element.prod_code,
                            qty: parseInt(element.qty),
                            quality_id: element.quality_id,
                            remark: element.remark,
                            tor_id: element.tor_id,
                            tr_id: element.tr_id,
                            wgt: element.wgt,
                            tr_seq: element.tr_seq,
                            dtl_seq: element.dtl_seq,
                            tr_id_ref: element.tr_id_ref,
                            tr_type: element.tr_type,
                            mill_id: element.mill_id,
                            wh_id: element.wh_id,
                            bin_id: element.bin_id,
                            display: false
                        }
                    )                    
                }
            }

        },
        deleteWhDetail(item){
            console.log(item);
            this.wh_detail = item
            this.dialog_delete_wh_detail = true
        },
        async deleteItem(){
            this.$store.dispatch('setOverlay', true)

            var reqBody = {
                mill_id: this.wh_detail.mill_id ? this.wh_detail.mill_id : '',
                tr_id: this.wh_detail.tr_id ? this.wh_detail.tr_id : '',
                BarangJadiId: this.wh_detail.BarangJadiId ? this.wh_detail.BarangJadiId : '',
                prod_code: this.wh_detail.prod_code ? this.wh_detail.prod_code.trim() : '',
                add_id: this.wh_detail.add_id ? this.wh_detail.add_id : '',
                tor_id: this.wh_detail.tor_id ? this.wh_detail.tor_id : '',
                acp_id: this.wh_detail.acp_id ? this.wh_detail.acp_id : '',
                panjang: this.wh_detail.panjang ? this.wh_detail.panjang : '',
                quality_id: this.wh_detail.quality_id ? this.wh_detail.quality_id : '',
                ord_flag: 'N',
                batch_id: this.wh_detail.batch_id ? this.wh_detail.batch_id : '',
                wh_id: this.wh_detail.wh_id ? this.wh_detail.wh_id : '',
                bin_id: this.wh_detail.bin_id ? this.wh_detail.bin_id : '02',
                qty_oh: parseInt(this.wh_detail.qty),
                remark: this.wh_detail.remark ? this.wh_detail.remark : '',
                tr_seq: this.wh_detail.tr_seq ? this.wh_detail.tr_seq : '',
                dtl_seq: this.wh_detail.dtl_seq ? this.wh_detail.dtl_seq : '',
                period: this.wh_detail.period ? this.wh_detail.period : '',
            }
            const respData = await backendApi.fetchCore('/api/v3/kka/hold-stock/destroy', reqBody, false, false, false)
            const respDataCount = await backendApi.fetchCore('/api/v3/kka/hold-stock/countItem', reqBody, false, false, false)

            if (respData.status === 200 && respDataCount.status === 200) {
                console.log(respDataCount.data);
                this.$store.dispatch('setOverlay', false)
                this.dialog_delete_wh_detail = false
                
                this.alert = true
                this.type_alert = 'success'
                this.alert_message = "Successfully delete item"

                if (this.dialog) {
                    await this.fetchDataWhBal()
                    await this.showTransaction()
                    if(parseInt(respDataCount.data) === 0){
                        this.tr_id = ''
                        this.disabled_header = false
                        this.disabled_mill = this.env_mill === 'Y' ? true : false
                        this.disabled_wh = this.env_wh === 'Y' ? true : false
                    }
                } else {
                    if(parseInt(respDataCount.data) === 0){
                        this.itemDetails = []
                        this.itemDetail = null
                        await this.fetchData()
                    } else {
                        await this.showItem(this.wh_detail)
                    }
                }

                this.wh_detail = null
                
            } else {
                this.dialog_delete_wh_detail = false
                this.$store.dispatch('setOverlay', false)
                this.alert = true
                this.type_alert = 'error'
                this.alert_message = respData.data
            }
        }, 
        async editItem(item){
            console.log(item);
            this.$store.dispatch('setOverlay', true)
            this.itemDetail = item
            var date = new Date(item.tr_date)
            this.trans_date =  (new Date( date - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
            this.mill = item.mill_id.trim()
            this.warehouse = item.wh_id.trim()
            await this.updateWarehouse(item.mill_id)
            this.tr_id = item.tr_id ? item.tr_id.trim() : ''
            this.remark = item.remark ? item.remark : ''
            this.disabled_header = true
            this.disabled_mill = true
            this.disabled_wh = true
            await this.showTransaction()
            await this.updateWarehouse(this.mill)
            await this.updateWarehouseFilter(this.mill_id)
            await this.getMachine(this.mill_id) 
            await this.getQuality(this.mill_id) 
            await this.getProdCode(this.mill_id) 
            await this.getAddId(this.mill_id) 
            await this.getTorId(this.mill_id)
            await this.getPeriod(this.mill_id)
            this.dialog = true
            this.$store.dispatch('setOverlay', false)
        }
    },
    watch: {
    }
}
</script>